.Content {
    margin-top: 84px;
    margin-bottom: 56px;
}

.Footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #78C2AD;
    color: white;
    align-items: center;    
    font-size: 0.875em;   
    height: 40px;
    display: flex;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;
}