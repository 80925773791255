.Modal {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 80%;
    height: 80%;
    box-shadow: 1px 1px 1px black;
    left: 10%;
    top: 15%;
    box-sizing: border-box;
    overflow:hidden;
    border-radius:5px;
    -moz-border-radius:5px;
    -webkit-border-radius:5px;
    /* transition: all 0.4s ease-out; */
}

.ModalInner {
    height: 100%;
    width: 100%;
    padding: 20px 30px;
    box-sizing: border-box;
    overflow-y: auto;
}

@media (min-width: 600px) {
    .Modal {
        width: 500px;
        left: calc(50% - 250px);
    }
}