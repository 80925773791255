.ExampleData {
    overflow-x: auto;
}

.Table {
    width: 100%;
    margin: 8px 0px;
    border: 1px solid black;
    border-collapse: collapse;
}

.LeftCell {
    padding: 4px;
    text-align: left;
    border: 1px solid black;
}

.RightCell {
    padding: 4px;
    text-align: left;
    border: 1px solid black;
}