.row {
    display: flex;
    padding: 0px;
}

.column-50 {
    flex: 50%;
    padding: 0px 10px;
}

.column-25 {
    flex: 20%;
    padding: 0px 10px;
    text-align: left;
}

.column-75 {
    flex: 80%;
    padding: 0px 10px;
}

@media (min-width: 700px) {
    .row {
        padding: 0px 50px;
    }
}