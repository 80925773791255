.Button {
    background-color: transparent;
    border: 1px solid black;
    /* color: white; */
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 8px;
    margin: 8px;
    font-weight: bold;
    border-radius: 5px;
}

.Button:disabled,
.Button:disabled:hover {
    color: #ccc;
    border-color: #ccc;
    cursor: not-allowed;
    background-color: transparent;
}

.Success {
    color: #5C9210;
    border-color: #5C9210;
}

.Success:hover {
    color: white;
    background-color: #5C9210;
}

.Danger {
    color: #944317;
    border-color: #944317;
}

.Danger:hover {
    color: white;
    background-color: #944317;
}

.Info {
    color: #78C2AD;
    border-color: #78C2AD;
}

.Info:hover {
    color: white;
    background-color: #78C2AD;
}

.Img {
    width: inherit;
    padding: 16px;
}

.Img:hover {
    background-color: #78C2AD;
}