.NavItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
}

.NavItem a {
    color: #78C2AD;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.NavItem a:hover,
.NavItem a:active,
.NavItem a.active {
    color: #40A4C8;
}

/* for desktop */
@media (min-width: 500px) {
    .NavItem {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }
    
    .NavItem a {
        color: white;
        height: 100%;
        padding: 16px 10px;
        border-bottom: 4px solid transparent;
    }
    
    .NavItem a:hover,
    .NavItem a:active,
    .NavItem a.active {
        background-color: rgb(64, 167, 138);
        border-bottom: 4px solid white;
        color: white;
    }
}